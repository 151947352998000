@import 'scss/variables';

body {
  .PlatformBanner {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
    padding: 15px;
    border-radius: 12px;

    img {
      object-fit: cover;
      object-position: center;
      border-radius: 12px;
    }

    .Left {
      width: 100%;
      max-width: 232px;

      .Date {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        margin-bottom: 8px;

        .Industry {
          white-space: nowrap;
          color: $color-red5;
        }

        .Name {
          color: $color-black4;
        }
      }
    }

    @media (min-width: $md) {
      max-width: 440px;
      gap: 10px;
    }
  }
}

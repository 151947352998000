@import 'scss/variables';

body {
  .QuizYesNoContainer {
    .QuizYesNo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 8px;
      padding: 8px 32px 32px;
      background-color: $color-black;
      border-radius: 24px;

      img {
        width: 180px;
        object-fit: cover;

        @media (min-width: $sm) {
          width: 240px;
        }
      }

      .Loading {
        width: 100%;
      }

      .ResultQuiz {
        width: 100%;

        .Title {
          color: $color-teal;
          margin-bottom: 26px;
        }

        .Result {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .Content {
            > h3 {
              color: $color-white;
              white-space: nowrap;
              margin-bottom: 18px;
            }

            .ConatinerProcent {
              display: grid;
              grid-template-columns: 4fr auto;
              column-gap: 20px;

              > h4 {
                color: $color-white;
              }

              .Procent {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 24px;
                width: 100%;
                min-width: 150px;

                .LoadingDefault {
                  position: relative;
                  width: 100%;
                  height: 12px;
                  border-radius: 4px;
                  background-color: rgba(#e5e5e5, 0.2);
                  color: rgba(#e5e5e5, 0.2);

                  .ProgressDefault {
                    position: absolute;
                    height: 12px;
                    border-radius: 4px;
                    top: 0;
                    left: 0;
                    background-color: $color-red5;
                    color: $color-red5;
                  }
                }

                @media (min-width: $md) {
                  min-width: 200px;
                }
              }
            }
          }

          @media (min-width: $md) {
            gap: 70px;
            flex-direction: row;
          }
        }
      }

      .ModContent {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .TextContent {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 593px;
          margin-bottom: 25px;
          gap: 8px;

          > p {
            color: $color-teal;
          }

          > h3 {
            color: $color-white;
          }

          @media (min-width: $md) {
            margin-right: 47px;
            margin-bottom: 0px;
          }
        }
        .Buttons {
          display: flex;
          justify-content: center;
          flex-direction: column;
          gap: 16px;
          width: 100%;
          max-width: 240px;

          @media (min-width: $sm) {
            flex-direction: row;
          }

          @media (min-width: $md) {
            flex-direction: column;
          }
        }

        @media (min-width: $sm) {
          flex-direction: column;
        }

        @media (min-width: $md) {
          flex-direction: row;
        }
      }

      @media (min-width: $sm) {
        padding: 32px;
        flex-direction: row;
      }
    }
  }
}

@import 'scss/variables';

body {
  .CardBigArticle {
    width: 100%;
    max-width: 480px;

    .ImageContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      height: 480px;
      margin-bottom: 16px;

      img {
        width: 100%;
        border-radius: 12px;
        aspect-ratio: auto 480 / 319;
        object-fit: cover;
        object-position: center;
      }
    }

    .Date {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      margin-bottom: 8px;

      .Industry {
        color: $color-red5;
      }

      .Name {
        color: $color-black4;
      }
    }

    .Title {
      margin-bottom: 16px;
    }
  }
}

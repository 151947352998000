@import 'scss/variables';

body {
  .money-made-bar-embed {
    display: none !important;
  }

  .MoneyChat {
    &.OpenIframe {
      background-color: #f2f6f8;
      padding-left: 10px;
      padding-right: 10px;

      @media (min-width: $md) {
        padding-left: 0px;
        padding-right: 0px;
      }
    }

    .TopBlock {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      background-color: $color-white;
      padding-top: 45px;
      padding-bottom: 52px;

      .Title {
        margin-bottom: 32px;
      }
      .Description {
        color: #45565b;
        margin-bottom: 40px;
      }

      .InputContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 656px;

        :global(.mu-container) {
          width: 100%;

          :global(.mu-input-container) {
            :global(.mu-input) {
              padding-left: 16px;
              font-size: 16px;
              background-color: transparent;
              border: none;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
              border-top-right-radius: 0px;
              border-bottom-right-radius: 0px;
              border-top: 1px solid #b1c0c5;
              border-bottom: 1px solid #b1c0c5;
              border-left: 1px solid #b1c0c5;
            }
          }
        }

        .SubmitBtn {
          background-color: #6affc1;
          border: none;
          border-radius: 0px 8px 8px 0px;
          padding: 8px 24px;
        }
      }

      @media (min-width: $md) {
        padding-top: 120px;
        padding-bottom: 124px;
      }
    }

    .PopularQuestions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #f4fafb;
      padding-top: 32px;
      padding-bottom: 104px;
      cursor: pointer;

      > h4 {
        color: #45565b;
        padding-bottom: 24px;
      }

      .Questions {
        display: grid;
        grid-template-columns: auto;
        column-gap: 32px;
        row-gap: 16px;
        padding-left: 15px;
        padding-right: 15px;

        .Question {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          gap: 8px;
          padding: 8px 16px;
          padding-left: 16px;
          padding-right: 16px;
          background-color: $color-white;
          border: 1px solid #0b7980;
          border-radius: 8px;

          > i {
            font-size: 12px;
            margin-top: 5px;
            color: #0b7980;
          }

          > p {
            color: #0b7980;
          }
        }

        @media (min-width: $md) {
          padding-left: 0px;
          padding-right: 0px;
          grid-template-columns: 421px 421px;
        }
      }
    }

    .IframeWrapper {
      padding-left: 0px;
      padding-right: 0px;
      .BackButton {
        display: flex;
        align-items: center;
        color: $color-black;
        gap: 16px;
        border: none;
        background-color: transparent;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-left: 8px;
      }
    }

    .ContentNew {
      display: flex;
      flex-direction: column;
      background-color: $color-black10;
      gap: 50px;

      > section {
        display: flex;
        flex-direction: column;
        gap: 50px;

        &:last-child {
          margin-bottom: 50px;
        }
      }
    }
  }
}

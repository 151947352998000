@import 'scss/variables';

body {
  .CardSmallArticle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    background-color: $color-white;
    border-radius: 12px;

    .Left {
      width: 100%;
      max-width: 232px;

      .Date {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        margin-bottom: 8px;

        .Industry {
          color: $color-red5;
        }

        .Name {
          color: $color-black4;
        }
      }
    }

    .ImageContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      width: 64px;
      height: 64px;
      margin-bottom: 16px;

      img {
        width: 100%;
        border-radius: 12px;
        aspect-ratio: auto 64 / 43;
        object-fit: cover;
        object-position: center;
      }
    }

    @media (min-width: $md) {
      max-width: 336px;
      gap: 10px;
    }
  }
}

@import 'scss/variables';

body {
  .Latest {
    .Header {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 15px;
      align-items: center;
      margin-bottom: 25px;

      .Link {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        color: $color-red5;

        > p {
          color: $color-red5;
        }
      }

      @media (min-width: $sm) {
        justify-content: space-between;
        gap: 0px;
      }
    }

    .Articles {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 24px;

      .Left {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;
        max-width: 336px;
      }

      @media (min-width: $md) {
        flex-direction: row;
      }
    }

    .SmallArticles {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 20px;

      @media (min-width: $sm) {
        flex-direction: row;
      }

      @media (min-width: $md) {
        margin-top: 0px;
        flex-direction: column;
      }
    }
  }
}
